<template>
  <v-sheet
    rounded="lg"
    width="100%"
    height="84px"
    color="wineSecondary"
    class="px-3 rounded-lg">
    <v-row
      no-gutters
      class="fill-height grid-operational-routines-container">
      <v-col
        :class="defaultHeaderClass"
        class="grid-column-3">
        <v-row
          no-gutters>
          <v-col
            cols="12">
            <span>
              Grupo
            </span>
          </v-col>
          <v-col
            cols="12">
            <span>
              Operadora
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :class="defaultHeaderClass"
        class="grid-column-1">
        <v-row
          no-gutters>
          <v-col
            cols="12">
            <span>
              Apólice
            </span>
          </v-col>
          <v-col
            cols="12">
            <span>
              Sub
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="grid-column-1">
        <div
          :class="defaultHeaderClass">
          Tipo
        </div>
      </v-col>
      <v-col
        class="grid-column-1">
        <div
          :class="defaultHeaderClass">
          Vidas
        </div>
      </v-col>
      <v-col
        class="grid-column-2">
        <div
          :class="defaultHeaderClass">
          Prêmio
        </div>
      </v-col>
      <v-col
        class="grid-column-2">
        <div
          :class="defaultHeaderClass">
          Iof
        </div>
      </v-col>
      <v-col
        class="grid-column-2">
        <div
          :class="defaultHeaderClass">
          Total de outros valores
        </div>
      </v-col>
      <v-col
        class="grid-column-2">
        <div
          :class="defaultHeaderClass">
          Retroatividade
        </div>
      </v-col>
      <v-col
        class="grid-column-2">
        <div
          :class="defaultHeaderClass">
          Prêmio total
        </div>
      </v-col>
      <v-col
        class="grid-column-1"
        style="width: 200px;">
        <div
          :class="defaultHeaderClass">
          Conferido
        </div>
      </v-col>
      <v-col
        class="grid-column-1">
        <div
          :class="defaultHeaderClass">
          Ações
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "ValuesHeader",

  data: () => ({
    defaultHeaderClass: "white--text text-subtitle-1 flex-item"
  })
}
</script>

<style>
.grid-operational-routines-container {
  display: grid !important;
  grid-template-columns: repeat(87, 1fr) !important;
  gap: 10px !important;
}
.grid-column-3 {
  grid-column: span 3 !important;
}
.grid-column-2 {
  grid-column: span 2 !important;
}

.grid-column-1 {
  grid-column: span 1 !important;
}
</style>
