var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"wineLabel--text pb-0 mb-0",staticStyle:{"font-size":"1.125rem"}},[_vm._v(" Selecione o contrato desejado: ")]),_c('v-simple-table',{staticClass:"overflow-auto",staticStyle:{"max-height":"215px","border":"1px solid rgba(0, 0, 0, .2)"}},[_c('thead',{staticClass:"wineSecondary"},[_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{key:header.text,staticClass:"text-left white--text text-subtitle-1 font-weight-medium",style:(header.style)},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.getInvoiceValidations),function(item,key){return _c('tr',{key:key,class:{
						'text-subtitle-1 wineLabel--text font-weight-regular': true
					},style:(_vm.invoiceSelected === item ? 'background: #EDE5EB' : ''),on:{"click":function($event){return _vm.selectInvoice(item)}}},[_c('td',{staticStyle:{"width":"320px"}},[_vm._v(" "+_vm._s(item.validationId)+" ")]),_c('td',{staticStyle:{"width":"320px"}},[_vm._v(" "+_vm._s(item.financialGroup.name)+" ")]),_c('td',{staticStyle:{"width":"auto"}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)])],1),(_vm.invoiceSelected && Object.keys(_vm.invoiceSelected).length > 0)?[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"wineLabel--text font-weight-bold text-h6"},[_vm._v(" Conferência de fatura ")])]),_vm._l(([
				{
					title: 'Total de registros',
					cards: [
						{
							label: 'Validados com sucesso',
							value: _vm.invoiceSelected.successRegistryDetailIds.length,
							icon: 'check',
							type: 'register',
							status: 'SUCCESS',
							isInvoice: true,
							key: 'successRegistryDetailIds'
						},
						{
							label: 'Validados com erro',
							value: _vm.invoiceSelected.errorRegistryDetailIds.length,
							icon: 'mdi mdi-exclamation',
							type: 'register',
							status: 'ERROR',
							isInvoice: true,
							key: 'errorRegistryDetailIds'
						},
						{
							label: 'Com erro - conferido',
							value: _vm.invoiceSelected.errorRegistryDetailIdsChecked.length,
							icon: 'mdi mdi-exclamation',
							secondaryIcon: 'check',
							type: 'register',
							status: 'ERROR',
							isInvoice: true,
							key: 'errorRegistryDetailIdsChecked'
						},
					]
				},
				{
					title: 'Total de lançamentos de valores',
					cards: [
						{
							label: 'Validados com sucesso',
							value: _vm.invoiceSelected.successValueDetailIds.length,
							icon: 'check',
							type: 'value',
							status: 'SUCCESS',
							isInvoice: false,
							key: 'successValueDetailIds'
						},
						{
							label: 'Validados com erro',
							value: _vm.invoiceSelected.errorValueDetailIds.length,
							icon: 'mdi mdi-exclamation',
							tpye: 'value',
							status: 'ERROR',
							isInvoice: false,
							key: 'errorValueDetailIds'
						},
						{
							label: 'Com erro - conferido',
							value: _vm.invoiceSelected.errorValueDetailIdsChecked.length,
							icon: 'mdi mdi-exclamation',
							secondaryIcon: 'check',
							type: 'value',
							status: 'ERROR',
							isInvoice: true,
							key: 'errorValueDetailIdsChecked'
						},
					]
				},
				{
					title: 'Total de registros não localizados',
					cards: [
						{
							multi: [
								{
									label: 'Fatura',
									value: _vm.invoiceSelected.notFoundInvoiceDetailIds.length,
									icon: 'search',
									type: 'register',
									status: 'NOT_FOUND',
									isInvoice: true,
									key: 'notFoundInvoiceDetailIds',
								},
								{
									label: 'Prévia',
									value: _vm.invoiceSelected.notFoundPreviewDetailIds.length,
									icon: 'search',
									type: 'value',
									status: 'NOT_FOUND',
									isInvoice: false,
									key: 'notFoundPreviewDetailIds',
								},
							]
						},
						{
							label: 'Fatura - conferido',
							value: _vm.invoiceSelected.notFoundInvoiceDetailIdsChecked.length,
							icon: 'mdi mdi-exclamation',
							secondaryIcon: 'search',
							type: 'value',
							status: 'NOT_FOUND',
							isInvoice: false,
							key: 'notFoundInvoiceDetailIdsChecked',
						},
						{
							label: 'Prévia - conferido',
							value: _vm.invoiceSelected.notFoundPreviewDetailIdsChecked.length,
							icon: 'mdi mdi-exclamation',
							secondaryIcon: 'search',
							type: 'register',
							status: 'ERROR',
							isInvoice: true,
							key: 'notFoundPreviewDetailIdsChecked'
						},
					]
				}
			]),function(item,registerKey){return _c('v-col',{key:'register' + registerKey},[_c('v-col',{staticClass:"d-flex flex-column pa-0"},[_c('span',{staticClass:"wineSecondary--text font-weight-bold text-subtitle-1"},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.cards),function(card,indexCard){return [(card.multi)?_c('div',[_c('v-sheet',[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((card.multi),function(innerCard,indexInnerCard){return [_c('v-col',[_c('ConferenceCard',{class:indexInnerCard <= 1 ? 'mb-6' : '',staticStyle:{"cursor":"pointer"},attrs:{"label":innerCard.label,"value":innerCard.value,"indexInnerCard":indexInnerCard,"isMulti":true},on:{"selectCard":function($event){return _vm.selectCard(registerKey, 'register', innerCard)}}})],1)]})],2)],1)],1):_c('div',[_c('ConferenceCard',{class:indexCard <= 1 ? 'mb-6' : '',staticStyle:{"cursor":"pointer"},attrs:{"label":card.label,"value":card.value,"icon":card.icon,"secondaryIcon":card.secondaryIcon},on:{"selectCard":function($event){return _vm.selectCard(registerKey, 'register', card)}}})],1)]})],2)],1)})]:_vm._e(),_c('SnackbarCustomize',{ref:"SnackbarCustomize"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }