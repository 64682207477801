import operationalRoutinesStore from '@/store/modules/operational-routines/operational-routines-module'
import HttpService from '../HttpService'

export default class OperationalService {
  constructor() {
    this._httpService = new HttpService('/invoice-service');
  }

  async SearchCarrierInvoiceValidation(params) {
    let hasData = false
    await this._httpService.get('/validations-cards', params).then(res => {
      hasData = res.data.length > 0 ? true : false;
      operationalRoutinesStore.setCarrierInvoiceValidations(res.data);
    });
    return hasData;
  };

  async SearchCarrierInvoicePriceValidation(params) {
    let hasData = false
    await this._httpService.get('/validations', params).then(res => {
      hasData = res.data.length > 0 ? true : false;
      operationalRoutinesStore.setCarrierInvoicePriceValidations(res.data);
    });
    return hasData;
  };

  async GetCarrierInvoiceValidationDetail(validationId) {
    await this._httpService.get(`/validations-details?validationId=${validationId}`).then(res => {
      operationalRoutinesStore.setCarrierInvoiceValidationDetail(res.data);
    });
  };

  async GetValidationDetail(detailId) {
    await this._httpService.get(`/detail/${detailId}`).then(res => {
      operationalRoutinesStore.setValidationDetails(res.data);
    });
  };

  async GetValidationDetailCriticism(detailId) {
    await this._httpService.get(`/criticism?validationDetailId=${detailId}`).then(res => {
      operationalRoutinesStore.setValidationDetailCriticism(res.data);
    });
  };

  async GetValidationComment(detailId) {
    await this._httpService.get(`/validation-comment?validationDetailId=${detailId}`).then(res => {
      operationalRoutinesStore.setValidationDetailComment(res.data);
    });
  };

  async GetValidationDetailComment(detailId) {
    await this._httpService.get(`/validation-detail-comment?validationDetailId=${detailId}`).then(res => {
      operationalRoutinesStore.setValidationDetailComment(res.data);
    });
  };

  async GetCarrierInvoiceTypes() {
    await this._httpService.get('/invoice-types').then(res => {
      operationalRoutinesStore.setCarrierInvoiceTypes(res.data);
    });
  };

  async CreateReport(data) {
    return await this._httpService.get('/validations-details-report', data, { responseType: 'blob' });
  };

  async UpdatePreviewTotals(data) {
    return await this._httpService.post('/update-preview-totals', data);
  };

  async CheckValidation(data) {
    return await this._httpService.put('/check-validation', data);
  };

  async CheckValidationDetail(data) {
    return await this._httpService.put('/check-validation-detail', data);
  };

  async PostValidationComment(data) {
    await this._httpService.post('/validation-comment', data);
  };

  async PostValidationDetailComment(data) {
    await this._httpService.post('/validation-detail-comment', data);
  };
}
