<template>
	<v-container
		fluid
		class="mx-0 px-0">
		<v-row
      class="mt-2 wine--text d-flex align-end justify-space-between">
      <v-col
        v-bind="columnsProps">
        <v-autocomplete
          v-model="filterOperationalRoutines.carrierId"
          v-bind="autoCompleteCarrierProps"
          label="Operadora*"
          :items="carriers"
          :rules="[rule.required]"
          hide-details
        />
      </v-col>
      <v-col
        v-bind="columnsProps">
        <v-autocomplete
          v-model="filterOperationalRoutines.financialGroupId"
          label="Grupo financeiro"
          v-bind="autoCompleteProps"
          :items="financialGroups"
          hide-details
        />
      </v-col>
      <v-col
        v-bind="columnsProps">
        <v-autocomplete
          v-model="filterOperationalRoutines.contractId"
          v-bind="autoCompleteProps"
          label="Contrato"
          :items="contracts"
          :loading="loadingContracts"
          hide-details
        />
      </v-col>
      <v-col
        v-bind="columnsProps">
        <v-autocomplete
          v-model="filterOperationalRoutines.subContractId"
          label="Subcontrato"
          v-bind="autoCompleteProps"
          :items="subContracts"
          :loading="loadingSubContracts"
          hide-details
        />
      </v-col>
      <v-col
        v-bind="columnsProps">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto">
          <template
            v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filterOperationalRoutines.competencyDate"
              placeholder="Selecione"
              label="Competência*"
              readonly
              outlined
              dense
              hide-details
              v-bind="autoCompleteProps"
              v-on="on"
              :rules="[rule.required]"
            />
          </template>
          <v-date-picker
            @click="setCompetence"
            v-model="date"
            type="month"
            locale="pt-br"
            color="wine"
            no-title
            scrollable>
            <v-spacer />
            <v-btn
              text
              @click="cleanCompetence">
              LIMPAR
            </v-btn>
            <v-btn
              text
              @click="setCompetence">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      class="mt-5">
      <v-spacer />
      <v-col
        cols="5"
        class="py-0 d-flex justify-end align-center">
        <span
          class="text-decoration-underline mr-2 wineSecondary--text"
          style="cursor: pointer;"
          @click="clearFilterOperationalRoutines">
          Limpar pesquisa
        </span>
        <v-btn
          class="white--text rounded-pill"
          height="39px"
          width="300"
          color="wine"
          @click="search()"
          :loading="loadingSearch">
          Pesquisar
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-show="overlay"
      class="wine--text my-15">
      <v-col
        cols="12"
        class="d-flex justify-center">
        <v-progress-circular
          color="wine"
          indeterminate
          size="64"
          width="8"
        />
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center">
        Carregando Filtros
      </v-col>
    </v-row>
	</v-container>
</template>

<script>
import OperationalRoutinesMixin from '@/shared/mixins/operationalRoutines/operationalRoutinesMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import DataLoadService from '@/services-http/sdi/DataLoadService';

import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';

import Rules from '@/shared/validators/formRules';

import moment from 'moment';
import { cloneDeep } from 'lodash';

export default {
	name: "SimpleFilter",

	data: () => ({
    overlay: false,
    loadingContracts: false,
    loadingSubContracts: false,
    watcherCalledGetContract: false,
    watcherCalledGetSubContract: false,
    dataLoadService: null,
    carriers: [],
		moment: moment,
		rule: null,
		menu: null,
		date: null,

		columnsProps: {
			// cols: "12",
			// sm: "6",
			// md: "3",
			// lg:"2",
			class: "py-0 flex-item",
		},

		autoCompleteProps: {
			class: "mt-1",
			placeholder: "Selecione",
			itemText: "name",
			itemValue: "id",
			outlined: true,
			clearable: true,
			dense: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
		autoCompleteCarrierProps: {
			class: "mt-1",
			placeholder: "Selecione",
			itemText: "carrierName",
			itemValue: "carrierId",
			outlined: true,
			clearable: true,
			dense: true,
			color: "wine--text",
			itemColor: "wine--text",
		},

		insuranceCarriers: [],
    financialGroups: [],
    contracts: [],
    subContracts: [],
	}),

	mixins: [
		OperationalRoutinesMixin,
		InsuranceCarriersMixin,
    FinancialGroupsMixin,
    ContractsMixin,
	],

	props: {
    loadingSearch: {
      type: Boolean,
			default: false
    }
	},

	watch: {
		date: {
      deep: true,
      handler(value) {
        if (value) this.setCompetence();
      },
    },
    'filterOperationalRoutines.financialGroupId': {
      deep: true,
      handler(value) {
        const financialGroupId = cloneDeep(value);
        if (financialGroupId) {
          if (!this.watcherCalledGetContract) {
            this.loadContractsByFinancialGroupId(financialGroupId);
          }
        } else {
          this.contracts = [];
          this.subContracts = [];
          this.watcherCalledGetContract = false;
        }
      },
    },
    'filterOperationalRoutines.contractId': {
      deep: true,
      handler(value) {
        if (value) {
          if (!this.watcherCalledGetSubContract) {
            this.loadSubContractsByContractId(value);
          }
        } else {
          this.subContracts = [];
          this.watcherCalledGetSubContract = false;
        }
      },
    },
	},

	methods: {
    async setMandatoryCarriers() {
			await this.dataLoadService.GetAllConfigMandatoryExtFile().then(res => {
				if (res.data.length > 0) {
					let mandatoryCarriers = [];
					res.data.forEach((carrier, index) => {
						if (index === 0 && carrier.carrierId != null) {
							mandatoryCarriers.push(carrier);
						}
						if (
							carrier.carrierId != null
							&& !mandatoryCarriers.some(item => item.carrierId === carrier.carrierId)
						) {
							mandatoryCarriers.push(carrier);
						}
					});
					mandatoryCarriers.sort((a, b) => (a.carrierName > b.carrierName ? 1 : -1));

          this.carriers = mandatoryCarriers;
				}
			})
		},
    search() {
      this.$emit('search', this.filterOperationalRoutines);
    },
		setCompetence() {
			if (Boolean(this.date)) {
				this.filterOperationalRoutines.competencyDate = moment(this.date, 'YYYY-MM').format('MM/YYYY');
			}
      this.menu = false;
    },
		cleanCompetence() {
      this.filterOperationalRoutines.competencyDate = null;
			this.date = null;
    },
		clearFilterOperationalRoutines() {
			this.updateFilterFields({}, true);
			this.$forceUpdate();
			this.$emit("resetStatusFilters", false)
		},
		updateFilterFields(data, cleanFields = false) {
			const fields = ["carrierId", "competencyDate", "financialGroupId", "contractId"];
			fields.forEach(field => {
				if (cleanFields) {
					this.filterOperationalRoutines[field] = null;
				} else if (data.hasOwnProperty(field)) {
					this.filterOperationalRoutines[field] = data[field];
				}
			})
		},
    loadContractsByFinancialGroupId(financialGroupId) {
      return new Promise((resolve) => {
        this.getContractsByFinancialGroupId(financialGroupId).then(() => {
          resolve();
        });
      });
    },
    async loadSubContractsByContractId(contractId) {
      return new Promise((resolve) => {
        this.getSubContractsByContractId(contractId).then(() => {
          resolve();
        });
      });
    },
	},

	computed: {
		getInvoiceCarrierTypes() {
			return operationalRoutinesModule.invoiceTypes;
		},
	},

	created() {
		this.rule = new Rules();
    this.dataLoadService = new DataLoadService();
	},

	async mounted() {
    this.overlay = true;
    await this.getFinancialGroups();
    await this.setMandatoryCarriers();
    this.overlay = false;
	},
}
</script>
