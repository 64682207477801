<template>
  <div
    class="fill-height white">
    <v-container
      fluid
      class="px-5">
      <v-row>
        <v-col
          cols="12"
          class="pb-0">
          <span
            class="text-subtitle-1 wineTitle--text">
            PAINEL DE CONTROLE DAS ROTINAS OPERACIONAIS
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12">
          <v-tabs
            id="OperationalRoutinesTabs"
            v-model="pageTab"
            background-color="transparent">
            <v-tabs-slider color="wine" style="height: 5px;" />
            <v-tab
              key='validations'
              href='#validations'
              :class="[
                pageTabClass,
                {
                  'wineTitle--text': pageTab !== 'validations',
                  'wineLabel--text':  pageTab === 'validations'
                }
              ]">
              Validações
            </v-tab>
            <v-tab
              key='details'
              href='#details'
              :class="[
                pageTabClass,
                {
                  'wineTitle--text': !['details', 'selected'].includes(pageTab),
                  'wineLabel--text': ['details', 'selected'].includes(pageTab)
                }
              ]"
              @click="notifyUser">
              Detalhes
            </v-tab>
            <v-tab
              key='selected'
              href='#selected'
              style="display: none;">
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="pageTab"
            class="transparent">
            <v-tab-item
              key='validations'
              value='validations'>
              <v-row
                class="mb-1">
                <v-col
                  cols=12>
                  <v-form
                    ref="SimpleFilter">
                    <SimpleFilter
                      @search="handlerFilter"
                      :loadingSearch="loadingSearch"
                    />
                  </v-form>
                </v-col>
                <v-col
                  cols="12">
                  <InvoiceConference
                    v-if="invoiceValidations.length > 0"
                    @selectCard="selectCard"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              key='details'
              value='details'>
              <InvoiceDetailsTable
                ref="InvoiceDetailsTable"
                :tab="'values'"
                :filter="filterOperationalRoutines"
                @showInvoiceDetails="showInvoiceDetails"
              />
            </v-tab-item>
            <v-tab-item
              key='selected'
              value='selected'>
              <InvoiceDetails
                ref="InvoiceDetails"
                :card="card"
                @close="closeInvoiceDetails"
                @updateInvoiceValidationPage="updateInvoiceValidationPage"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-footer
        class="my-5 px-0 transparent">
        <v-row
          class="d-flex justify-end">
          <v-col
            v-bind="columnFooterProps"
            v-show="pageTab !== 'selected'">
            <v-btn
              x-large
              block
              outlined
              color="wine"
              @click="onClickBack()">
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-footer>
    </v-container>
    <SnackbarCustomize ref="SnackbarCustomize" />
    <FullScreenLoader
			v-if="loaded"
		/>
  </div>
</template>
<script>
import InvoiceDetailsTable from '@/components/OperationalRoutines/InvoiceDetailsTable.vue';
import InvoiceConference from '@/components/OperationalRoutines/InvoiceConference.vue';
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue';
import InvoiceDetails from '@/components/OperationalRoutines/InvoiceDetails.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import SimpleFilter from '@/components/OperationalRoutines/SimpleFilter.vue'
import CustomFilter from '@/components/CustomFilter/CustomFilter.vue';

import OperationalRoutinesMixin from '@/shared/mixins/operationalRoutines/operationalRoutinesMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';

import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';
import OperationalService from '@/services-http/operational/OperationalService';

import { cloneDeep } from 'lodash';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    InvoiceDetailsTable,
    SnackbarCustomize,
    InvoiceConference,
    FullScreenLoader,
    InvoiceDetails,
    CustomFilter,
    SimpleFilter,
  },

  data: () => ({
    moment: moment,
    pageTab: 'validations',
    pageTabClass: 'text-h6 font-weight-bold px-0 mr-8' ,
    operationalService: null,
    loadingSearch: false,
    filterOperationalRoutines: {},
    card: {},
    loaded: false,

    columnFooterProps: {
      cols: "12",
      xs: "12",
      sm: "12",
      md: "2",
      lg: "2",
    }
  }),

  mixins: [
    OperationalRoutinesMixin,
    VerifyRoutesMixin,
    FinancialGroupsMixin,
    GenericFunctionsMixin
  ],

  methods: {
    ...mapMutations({
      setCardValidationId: 'operational-routines/setCardValidationId'
    }),
    onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
    notifyUser() {
      if (this.carrierInvoicePriceValidation.length === 0) {
        this.$refs.SnackbarCustomize.open('error', 'Realize uma pesquisa para ver os detalhes das faturas');
      }
    },
    async selectCard(card) {
      this.pageTab = 'details';
      await this.showInvoiceDetails(card.invoiceSelected.validationId, card);
    },
    async showInvoiceDetails(validationId, card) {
      this.loaded = true;
			await this.getInvoiceDetails(validationId);
      this.pageTab = 'selected';
      this.card = card;
      this.loaded = false;
		},
    closeInvoiceDetails() {
      this.card = {};
      this.pageTab = 'details';
      this.setCardValidationId(null);
    },
    async updateInvoiceValidationPage() {
      await this.getInvoiceDetails(this.cardValidationId);
      this.setCardValidationId(null);
    },
    async getInvoiceDetails(validationId) {
      if (validationId) {
        this.setCardValidationId(validationId);
        await this.operationalService.GetCarrierInvoiceValidationDetail(validationId);
      }
		},
    async handlerFilter(value) {
      if (!this.$refs.SimpleFilter.validate()) return;
      this.loadingSearch = true;
			this.filterOperationalRoutines = cloneDeep(value);
      if (this.filterOperationalRoutines.competencyDate) {
        this.filterOperationalRoutines.competencyDate = moment(
          this.filterOperationalRoutines.competencyDate, 'MM/YYYY').format('YYYY-MM');
      }
			await this.getCarrierInvoces()
		},
    async getCarrierInvoces() {
      this.deleteEmptyParams(this.filterOperationalRoutines);
      const params = new URLSearchParams(this.filterOperationalRoutines)
      const result = await Promise.all([
        this.operationalService.SearchCarrierInvoiceValidation(params),
        this.operationalService.SearchCarrierInvoicePriceValidation(params)
      ])

      if (!result[0]) this.$refs.SnackbarCustomize.open('error', 'Nenhum contrato foi encontrado');
      this.loadingSearch = false;
    },
    deleteEmptyParams(params) {
			Object.keys(params).forEach((key) => {
				if (["", null, undefined].some(item => item === params[key])) delete params[key]
			})
		},
  },

  computed: {
    ...mapGetters({
      cardValidationId: 'operational-routines/cardValidationId'
    }),
    invoiceValidations() {
			return operationalRoutinesModule.invoiceValidations;
		},
    carrierInvoicePriceValidation() {
			return operationalRoutinesModule.invoicePriceValidations;
		},
  },

  created() {
    this.operationalService = new OperationalService();
  },

  async mounted() {
    this.operationalService.GetCarrierInvoiceTypes();

    if (operationalRoutinesModule.invoiceValidations.length === 0)
      this.$refs.SnackbarCustomize.open(
        'error',
        'Utilize os campos de pesquisa para a busca dos dados'
      );
  },

  destroyed() {
    operationalRoutinesModule.resetModule();
  }
}
</script>

<style>
#OperationalRoutinesTabs div .v-tabs-slider-wrapper {
  height: 3px !important;
}
</style>
