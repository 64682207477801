<template>
	<v-container
		fluid
		class="pb-0">
		<v-row>
			<v-col
				cols="12"
				class="mt-2 pt-0 d-flex flex-row align-end">
				<span
					class="wineLabel--text"
					style="font-size: 1.125rem;">
					Detalhes das faturas
				</span>
				<v-spacer />
				<!-- <v-btn
					height="50"
					width="263"
					class="wine white--text rounded-pill text-h6"
					:loading="loadingExport"
					@click="exportInvoice">
					Exportar
				</v-btn> -->
			</v-col>
			<v-col cols="12" class="py-0">
				<v-data-table
					class="elevation-0 transparent"
					item-key="id"
					:items="carrierInvoicePriceValidation.filter(invoice => setValidationContract(invoice))"
					:loading="loading"
					:items-per-page="itemsPerPage"
					:page="page"
					:custon-sort="{
						isDescending: true
					}"
					:footer-props="{
						itemsPerPageOptions: [10, 25, 50, 75, 100],
						disablePagination: loading
					}"
          hide-default-header
				>
					<template v-slot:no-data>
						<span>Nenhum dado encontrado.</span>
					</template>
					<template v-slot:header>
						<v-container
							fluid>
							<v-row>
								<ValuesHeader />
							</v-row>
						</v-container>
					</template>
					<template v-slot:item="{ item }">
						<ValuesTable
							:item="item"
							@openInvoiceModal="openInvoiceModal"
							@showInvoiceDetails="$emit('showInvoiceDetails', $event, {})"
						/>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<SnackbarCustomize ref="SnackbarCustomize" />
		<CriticsInvoiceModal ref="CriticsInvoiceModal" :hasComment="false" />
		<InvoiceModal ref="InvoiceModal" :hasComment="true" :filter="filter" />
	</v-container>
</template>

<script>
import CriticsInvoiceModal from '../Modals/CriticsInvoiceModal/CriticsInvoiceModal.vue';
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";
import InvoiceModal from '../Modals/InvoiceModal/InvoiceModal.vue';
import ValuesHeader from "./ValuesHeader.vue";
import ValuesTable from "./ValuesTable.vue";

import OperationalRoutinesMixin from "@/shared/mixins/operationalRoutines/operationalRoutinesMixin";
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';


import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';
import OperationalService from "@/services-http/operational/OperationalService.js";

export default {
	name: "InvoiceDetailsTable",

	components: {
		CriticsInvoiceModal,
		SnackbarCustomize,
		InvoiceModal,
		ValuesHeader,
		ValuesTable,
	},

	data: () => ({
		loadingExport: false,
		loading: false,

		operationalService: null,

		operationalRoutines: [],

		itemsPerPage: 10,
		page: 1,
	}),

	mixins: [
		OperationalRoutinesMixin,
		FinancialGroupsMixin,
		VerifyRoutesMixin,
		ContractsMixin,
	],

	props: {
		filter: null
	},

	watch: {
		filterOperational: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) this.$emit("hasFiltersOperational", this.filterOperational)
			}
		},
	},

	methods: {
		openInvoiceModal(invoice) {
			this.$refs.InvoiceModal.open(invoice);
		},
		setValidationContract(invoice) {
			if (Object.entries(this.contractSelected).length > 0) {
				return invoice.validationId === this.contractSelected.validationId;
			}
			return Object.entries(invoice).length > 0 === true;
		},
	},

	computed: {
		carrierInvoicePriceValidation() {
			return operationalRoutinesModule.invoicePriceValidations;
		},
		contractSelected() {
			return operationalRoutinesModule.contractSelect;
		},
	},

	created() {
		this.operationalService = new OperationalService();
	},
}
</script>
