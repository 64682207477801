var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height white"},[_c('v-container',{staticClass:"px-5",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 wineTitle--text"},[_vm._v(" PAINEL DE CONTROLE DAS ROTINAS OPERACIONAIS ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"id":"OperationalRoutinesTabs","background-color":"transparent"},model:{value:(_vm.pageTab),callback:function ($$v) {_vm.pageTab=$$v},expression:"pageTab"}},[_c('v-tabs-slider',{staticStyle:{"height":"5px"},attrs:{"color":"wine"}}),_c('v-tab',{key:"validations",class:[
                _vm.pageTabClass,
                {
                  'wineTitle--text': _vm.pageTab !== 'validations',
                  'wineLabel--text':  _vm.pageTab === 'validations'
                }
              ],attrs:{"href":"#validations"}},[_vm._v(" Validações ")]),_c('v-tab',{key:"details",class:[
                _vm.pageTabClass,
                {
                  'wineTitle--text': !['details', 'selected'].includes(_vm.pageTab),
                  'wineLabel--text': ['details', 'selected'].includes(_vm.pageTab)
                }
              ],attrs:{"href":"#details"},on:{"click":_vm.notifyUser}},[_vm._v(" Detalhes ")]),_c('v-tab',{key:"selected",staticStyle:{"display":"none"},attrs:{"href":"#selected"}})],1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.pageTab),callback:function ($$v) {_vm.pageTab=$$v},expression:"pageTab"}},[_c('v-tab-item',{key:"validations",attrs:{"value":"validations"}},[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"SimpleFilter"},[_c('SimpleFilter',{attrs:{"loadingSearch":_vm.loadingSearch},on:{"search":_vm.handlerFilter}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.invoiceValidations.length > 0)?_c('InvoiceConference',{on:{"selectCard":_vm.selectCard}}):_vm._e()],1)],1)],1),_c('v-tab-item',{key:"details",attrs:{"value":"details"}},[_c('InvoiceDetailsTable',{ref:"InvoiceDetailsTable",attrs:{"tab":'values',"filter":_vm.filterOperationalRoutines},on:{"showInvoiceDetails":_vm.showInvoiceDetails}})],1),_c('v-tab-item',{key:"selected",attrs:{"value":"selected"}},[_c('InvoiceDetails',{ref:"InvoiceDetails",attrs:{"card":_vm.card},on:{"close":_vm.closeInvoiceDetails,"updateInvoiceValidationPage":_vm.updateInvoiceValidationPage}})],1)],1)],1)],1),_c('v-footer',{staticClass:"my-5 px-0 transparent"},[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.pageTab !== 'selected'),expression:"pageTab !== 'selected'"}]},'v-col',_vm.columnFooterProps,false),[_c('v-btn',{attrs:{"x-large":"","block":"","outlined":"","color":"wine"},on:{"click":function($event){return _vm.onClickBack()}}},[_vm._v(" Voltar ")])],1)],1)],1)],1),_c('SnackbarCustomize',{ref:"SnackbarCustomize"}),(_vm.loaded)?_c('FullScreenLoader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }